import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, 
    SearchInput, DateField, Show, SimpleShowLayout, 
    RichTextField, ShowButton, ReferenceInput, SelectInput, ReferenceField } from "react-admin";

const storeFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Name" source="name" />,
];

// detect using: ListGuesser
export const StoreList = (props: any) => (
    <List {...props} filters={storeFilters} >
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="customer_id" reference="customers">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="address" />
            <TextField source="city" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
export const StoreEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="customer_id" reference="customers">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />
            <TextInput source="phone" />
            <TextInput source="email" />
            <TextInput source="address" />
            <TextInput source="coordinates" />
            <TextInput source="city" />
            <TextInput source="country_code" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const StoreCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <ReferenceInput source="customer_id" reference="customers">
                <SelectInput optionText="name" />
            </ReferenceInput>  
            <TextInput source="name" />
            <TextInput source="phone" />
            <TextInput source="email" />
            <TextInput source="address" />
            <TextInput source="coordinates" />
            <TextInput source="city" />
            <TextInput source="country_code" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);


export const StoreShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField label="Customer Id" source="customer_id" />
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="address" />
            <TextField source="coordinates" />
            <TextField source="city" />
            <TextField source="country_code" />
            <RichTextField source="description" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);
