import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, 
    NumberInput, Show, SimpleShowLayout, ShowButton, ReferenceInput, SelectInput, DateTimeInput, 
    BooleanInput, AutocompleteInput, 
    FunctionField} from "react-admin";
import { IsActiveIcon } from "../Consts";


const scheduleFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Pin" source="pin" alwaysOn />,
];

// detect using: ListGuesser
// todo: add non-inventory and coount/inventory hours
export const ScheduleList = (props: any) => (
    <List {...props} filters={scheduleFilters} >
        <Datagrid>
            <TextField source="event_id" />
            <TextField source="pin" />
            <TextField source="auditor_id" />
            <TextField source="total_working_hours" />
            <TextField source="total_non_inventory_hours" />
            <TextField source="total_pause_hours" />
            <TextField source="total_pause_count" />
            <TextField source="auditor_aph" />
            <TextField source="auditor_zone" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
export const ScheduleEdit = () => (
    <Edit>
        <SimpleForm>
            <ReferenceInput source="event_id" reference="events">
                <SelectInput optionText="id" />
            </ReferenceInput>
            <ReferenceInput label="Badges" source="auditor_id" reference="auditors" >
                <AutocompleteInput optionText={record => record.firstname} />
            </ReferenceInput>
            <BooleanInput source="auditor_present" label="Auditor present?" />
            <DateTimeInput source="start_work_time" />
            <DateTimeInput source="end_work_time" />
            <TextInput source="total_working_hours" />
            <TextInput source="total_non_inventory_hours" />
            <TextInput source="total_pause_hours" />
            <TextInput source="total_pause_count" />
            <NumberInput source="auditor_aph" />
            <TextInput source="auditor_zone" />
        </SimpleForm>
    </Edit>
);

export const ScheduleCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <ReferenceInput source="event_id" reference="events">
                <SelectInput optionText="id" />
            </ReferenceInput>
            <ReferenceInput label="Badges" source="auditor_id" reference="auditors" >
                <AutocompleteInput optionText={record => record.firstname} />
            </ReferenceInput>
            <BooleanInput source="auditor_present" />
            <DateTimeInput source="start_work_time" />
            <DateTimeInput source="end_work_time" />
            <TextInput source="total_working_hours" />
            <TextInput source="total_non_inventory_hours" />
            <TextInput source="total_pause_hours" />
            <TextInput source="total_pause_count" />
            <NumberInput source="auditor_aph" />
            <TextInput source="auditor_zone" />
        </SimpleForm>
    </Create>
);

export const ScheduleShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="event_id" />
            <TextField source="auditor_id" />
            <FunctionField
                label="Auditor present?"
                render={(record: any) => <IsActiveIcon isActive={record.auditor_present} />}
            />
            <TextField source="start_work_time" />
            <TextField source="end_work_time" />
            <TextField source="total_working_hours" />
            <TextField source="total_non_inventory_hours" />
            <TextField source="total_pause_hours" />
            <TextField source="total_pause_count" />
            <TextField source="auditor_aph" />
            <TextField source="auditor_zone" />
        </SimpleShowLayout>
    </Show>
);
