import { AuthProvider } from 'react-admin';
import { Tokens, API_BASE_URL } from './Consts';
import { AvatarIcon } from './imgs/base64';

const authProvider: AuthProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
        const email = username;
        const request = new Request(`${API_BASE_URL}/local/signin`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Connection': 'keep-alive',
            }),
        });

        const loginFlow = await fetch(request)
            .then(response => {
                checkErrorThrow(response);
                return response.json();
            })
            .then(authData => {
                storeTokens(authData);
                return Promise.resolve();
            })
            .catch(err => {
                //throw new Error('Network error');
                return Promise.reject()
            });

            return loginFlow !== null ? loginFlow : Promise.reject();
    },
    // called when the user clicks on the logout button
    logout: async () => {
        const auth = localStorage.getItem('auth');
        let tokens: Tokens = auth != null ? JSON.parse(auth) : null;
        if(tokens == null || tokens.access_token == null) {
            clearTokens();
            return Promise.resolve();
        }
        // todo: consider also if access_token / refresh_token is expired or not before making the request!
        const request = new Request(`${API_BASE_URL}/logout`, {
            method: 'GET',
            headers: new Headers({ 
                'Accept': '*/*',
                'Authorization': 'Bearer ' + tokens.access_token
            }),
        });
        const logoutFlow = await fetch(request)
            .then(auth => {
                clearTokens();
                return Promise.resolve();
            })
            .catch(err => {
                clearTokens();
                return Promise.resolve();
            });
        return logoutFlow !== null ? logoutFlow : Promise.reject();
    },
    // called when the API returns an error
    checkError: (error) => {
        //error => {"status":401,"body":{"statusCode":401,"message":"Unauthorized"},"name":"HttpError"}

        if(error !== undefined && error !== "Unknown method") {
            // todo: "error" parameter not found!!!!!!!!!!!!!!!!!
            console.log("errFull here:" + JSON.stringify(error));

            // todo: add expiresIn for access_token at server-response { access_token, refresh_token, expires_in }
            //    ..memorize timestamp when tokens were recieved to calculate if it's expired!

            const status = error["status"];

            // console.js:213 TypeError: Cannot read properties of undefined (reading 'status')
            // at Object.checkError (AuthProvider.ts:69:1)
            // at useLogoutIfAccessDenied.ts:48:1
            // at useDataProvider.ts:117:1

            if (status === 401 || status === 403) {
                // LOGIN with refresh-token instead?
                loginWithRefreshToken();
                // clearTokens();
                // return Promise.reject();
            }
        }

        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: ({ statusText, status, json }) => {
        // check also if token is expired?
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: () => {
        let currUsr = localStorage.getItem('firstname');
        return Promise.resolve({
            id: 'user',
            fullName: (currUsr !== null) ? currUsr : "anonymous",
            avatar: AvatarIcon,
        });
    }
};

export const isTokenExpired = () : Boolean => {
    const auth = localStorage.getItem('auth');
    let tokens: Tokens = auth != null ? JSON.parse(auth) : null;
    let isExpired = false;
    if(tokens != null) {
        const expiresInSec = tokens.expires_in;
        const authTstMillis = Number(localStorage.getItem('auth_tst'));        
        const spentTimeSec = (Date.now() - authTstMillis) / 1000;
        isExpired = (spentTimeSec > expiresInSec);
    }
    return isExpired;
}

export const loginWithRefreshToken = async() => {
    const auth = localStorage.getItem('auth');
    let tokens: Tokens = auth != null ? JSON.parse(auth) : null;
    if(tokens == null || tokens.access_token == null) {
        clearTokens();
        return Promise.reject();
    }

    const request = new Request(`${API_BASE_URL}/refresh`, {
        method: 'GET',
        headers: new Headers({
            'Accept': '*/*',
            'Authorization': 'Bearer ' + tokens.refresh_token
        }),
    });

    const loginFlow = await fetch(request)
        .then(response => {
            checkErrorThrow(response);
            return response.json();
        })
        .then(authData => {
            storeTokens(authData);
            return Promise.resolve();
        })
        .catch(err => {
            clearTokens();
            return Promise.reject()
        });

        return loginFlow !== null ? loginFlow : Promise.reject();
}

function clearTokens() {
    localStorage.removeItem('auth');
    localStorage.removeItem('auth_tst');
    localStorage.removeItem('firstname');
}

function storeTokens(authData: Tokens) {
    localStorage.setItem('auth', JSON.stringify(authData));
    localStorage.setItem('auth_tst', Date.now().toString());
    localStorage.setItem('firstname', authData.firstname);
}

function checkErrorThrow(response: Response) {
    if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
    }
}

export default authProvider;
