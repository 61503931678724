import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, DateInput, DateField, NumberInput, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, Show, SimpleShowLayout, ShowButton, SimpleList, SelectInput, FunctionField } from "react-admin";

const countChoices = [
    { id: 0, name: 'MULTIPLE' },
    { id: 1, name: 'SINGLE' },
    { id: 2, name: 'MIX' },
];

const RetrieveType = (countType: number) => {
    const count = countChoices.find(choice => choice.id === countType);
    return count ? count.name : 'Unknown';
};

const customerFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Name" source="name" />,
];

// detect using: ListGuesser
// todo: add website field?
export const CustomerList = (props: any) => (
    <List {...props} filters={customerFilters} >
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="description" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
export const CustomerEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <ArrayInput source="zones">
                <SimpleFormIterator inline>
                    <TextInput source="name" helperText={false} />
                    <NumberInput source="aph" helperText={false} />
                    <SelectInput source="count" choices={countChoices} helperText={false} />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="phone" />
            <TextInput source="email" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const CustomerCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="name" />
            <ArrayInput source="zones">
                <SimpleFormIterator inline>
                    <TextInput source="name" helperText={false} />
                    <NumberInput source="aph" helperText={false} />
                    <SelectInput source="count" defaultValue={0} choices={countChoices} helperText={false} />
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="phone" />
            <TextInput source="email" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);

export const CustomerShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="description" />
            <ArrayField source="zones">
                <SimpleList
                    primaryText={(record) => `Name: ${record.name}`}
                    secondaryText={(record) => `APH: ${record.aph}, Count: ${RetrieveType(record.count)}`}
                    linkType={false}
                />
            </ArrayField>
            <TextField source="createdAt" />
            <TextField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);
