import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, DateInput, DateField, Show, SimpleShowLayout, ShowButton } from "react-admin";

const auditorFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Firstname" source="firstname" />,
    <TextInput label="Lastname" source="lastname" />,
];

// detect using: ListGuesser
export const AuditorList = (props: any) => (
    <List {...props} filters={auditorFilters} >
        <Datagrid>
            <TextField label="Badge" source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="city" />
            <DateField source="birthday" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
export const AuditorEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput label="Badge" disabled source="id" />
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            <TextInput source="phone" />
            <TextInput source="email" />
            <DateInput source="birthday" />
            <TextInput source="city" />
        </SimpleForm>
    </Edit>
);

export const AuditorCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            <TextInput source="phone" />
            <TextInput source="email" />
            <DateInput source="birthday" />
            <TextInput source="city" />
        </SimpleForm>
    </Create>
);

export const AuditorShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField label="Badge" source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="phone" />
            <TextField source="email" />
            <DateField source="birthday" />
            <TextField source="city" />
            <TextField source="createdAt" />
            <TextField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);
