// in src/users.js
import { List, Datagrid, TextField, TextInput, 
    Edit, NumberInput, SimpleForm, EditButton, Create, BooleanInput, PasswordInput, SearchInput, 
    FunctionField} from 'react-admin';
import { IsActiveIcon } from '../Consts';

const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput label="Username" source="username" />,
    <TextInput label="Is Active?" source="isActive" />,
];

// detect using: ListGuesser
export const UserList = (props: any) => (
    <List {...props} filters={userFilters}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="createdAt" />
            <TextField source="updatedAt" />
            <FunctionField
                label="Active?"
                render={(record: any) => <IsActiveIcon isActive={record.isActive} />}
            />
            <TextField source="role" />
            <EditButton />
        </Datagrid>
    </List>
);

// detect using: EditGuesser
export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="username" />
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            <BooleanInput source="isActive" />
            <NumberInput source="role" />
        </SimpleForm>
    </Edit>
);

export const UserCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="email" />
            <PasswordInput source="password" />
            <TextInput source="firstname" />
            <TextInput source="lastname" />
            <BooleanInput source="isActive" />
            <NumberInput source="role" />
        </SimpleForm>
    </Create>
);
