import { 
  fetchUtils,
  DataProvider, 
  withLifecycleCallbacks
} from 'react-admin';

import simpleRestProvider from 'ra-data-simple-rest';
import { isTokenExpired, loginWithRefreshToken } from './AuthProvider';

import { CheckCircle, Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import log from 'loglevel';
log.setLevel('debug'); // Set the desired log level (e.g., 'info', 'debug')

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export interface Tokens {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    firstname: string;
}

// ### data provider utilities ###
const httpClient = async(url: string, options: any = {}) => {
    if(isTokenExpired()) {
      await loginWithRefreshToken();
    }
    const auth = localStorage.getItem('auth');
    let tokens: Tokens = auth != null ? JSON.parse(auth) : null;
  
    if (!options.headers) {
      options.headers = new Headers({ 
        Accept: 'application/json',         
      });
    }
    if(tokens != null) {
      options.headers.set('Authorization', 'Bearer ' + tokens.access_token);
    }
    options.headers.set('Access-Control-Expose-Headers', 'X-Total-Count');
    return fetchUtils.fetchJson(url, options);
  };

const baseDataProvider = simpleRestProvider(`${API_BASE_URL}`, httpClient);

export const dataProvider = withLifecycleCallbacks(baseDataProvider, [
  {
      /**
       * For posts update only, convert uploaded images to base 64 and attach them to
       * the `picture` sent property, with `src` and `title` attributes.
       */
      resource: 'events',
      beforeCreate: async (params: any, dataProvider: DataProvider) => {
          // Freshly dropped attachment are File objects and must be converted to base64 strings
          console.log("start create.......................");
          const attachments = await buildAttachments(params);
          return {
              ...params,
              data: {
                  ...params.data, attachments,
              }
          };
      },
      beforeUpdate: async (params: any, dataProvider: DataProvider) => {
          // Freshly dropped attachment are File objects and must be converted to base64 strings
          console.log("start update.......................");
          const attachments = await buildAttachments(params);
          return {
              ...params,
              data: {
                  ...params.data, attachments,
              }
          };
      }
  }
]);

async function buildAttachments(params: any) {
    var attachments: any[] = [];
    if(typeof params.data.attachments_info !== "undefined" && params.data.attachments_info != null) {
      // #console.js:213 TypeError: params.data.attachments_info.filter is not a functio
      const newAttachments = params.data.attachments_info.filter(
          (p: any) => p.rawFile instanceof File
      );
      const formerAttachments = params.data.attachments_info.filter(
          (p: any) => !(p.rawFile instanceof File)
      );

      const base64Attachments = await Promise.all(
          newAttachments.map(convertFileToBase64)
      )
      
      attachments = [
          ...base64Attachments.map((dataUrl, index) => ({
              src: dataUrl,
              title: newAttachments[index].name,
          })),
          ...formerAttachments,
      ];
    }
    return attachments;
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of dataprovider decoration.
 */
const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/* logger */
export const debugLog = (msg: string) => {
  // log types: trace, debug, info, warn, error
  log.debug(msg);
}

/* UI helper methods */
export const IsActiveIcon = ({ isActive }: { isActive: number }) => (
  <Tooltip title={isActive ? 'Active' : 'Inactive'}>
      {isActive 
          ? (<CheckCircle style={{ color: 'green' }} />)
          : (<Cancel style={{ color: 'red' }} />)
      }
  </Tooltip>
);
